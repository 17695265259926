@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.find-member {
  &__header {
    background-color: lightmode.$primary;
    padding: spacing.$l 0;
  }

  &__title {
    color: lightmode.$on-primary;
  }

  &__search-wrapper {
    background-color: lightmode.$clean;
    padding: spacing.$xl 0 spacing.$l;
    margin-bottom: spacing.$l;
  }

  &__search {
    width: 100%;
  }

  &__sub-title {
    margin-bottom: spacing.$m;
  }

  &__empty-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: spacing.$xl;
  }

  &__empty-list-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: spacing.$xs;
  }

  &__spinner {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(white, 0.7);
  }

  &__pagination {
    margin: spacing.$xl 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: spacing.$s spacing.$xxl;
    flex-wrap: wrap;
    padding: 0 spacing.$l;
  }

  &__members {
    display: flex;
    align-items: flex-start;
    gap: spacing.$l;
    margin-top: spacing.$m;
    margin-bottom: spacing.$xl;
    position: relative;
  }

  &__member-list {
    color: lightmode.$on-background-primary;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-grow: 1;
  }
}
